/**
 * Reset some basic elements
 */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
hr,
dl,
dd,
ol,
ul,
figure {
  margin: 0;
  padding: 0;
}



/**
  * Basic styling
  */

* {
  box-sizing: border-box;
}

html {
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

body {
  font-family: $base-font-family;
  font-size: $base-font-size;
  line-height: $base-line-height;
  letter-spacing: $base-letter-spacing;
  font-weight: 300;
  color: $text-color;
  background-color: $background-color;
  -webkit-text-size-adjust: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $base-title-family;
}

/**
  * Set `margin-bottom` to maintain vertical rhythm
  */
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
ul,
ol,
dl,
figure,
%vertical-rhythm {
  margin-bottom: $spacing-unit / 2;
}


/**
  * Images
  */
img {
  max-width: 100%;
  vertical-align: middle;
}



/**
  * Figures
  */
figure>img {
  display: block;
}

figcaption,
.caption {
  font-size: $small-font-size;
  text-align: center;
}



/**
  * Lists
  */
ul,
ol {
  margin-left: $spacing-unit;
}

li {

  >ul,
  >ol {
    margin-bottom: 0;
  }
}



/**
  * Headings
  */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}

strong, b {
  font-weight: bold;
}

/**
  * Links
  */
a {
  color: $text-color;
  text-decoration: none;
  transition: all 0.25s ease;
  -webkit-transition: 0.25s ease;

  &:hover {
    color: rgba(0, 0, 0, .54);
  }

  &.post-link {
    color: $text-color;

    &:hover {
      color: $brand-color;
    }
  }
}

/**
  * Clearfix
  */
%clearfix {

  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

/**
  * Table
  */

table {
  border: 1px solid $grey-color-border;
  border-collapse: collapse;
  border-left: none;
  word-break: normal;

  tr {
    -webkit-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
    background-color: $white;
  }

  tr > td {
    min-width: 100px;
  }

  th {
    padding: 8px 16px;
    background-color: $grey-color;
    font-weight: bold;
  }

  td {
    font-size: 14px;
    border: 1px solid $grey-color-border;
    vertical-align: middle;
    padding: 8px 16px;
    height: 40px;
  }

  tr:nth-of-type(2n) {
    background-color: #F9F9F9 !important;
  }
}