// GLOBAL
// -------

body {
  width: 100vw;
  overflow-x: hidden;
  overflow-y: scroll;
}

.full-screen {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
}

// HEADER
// -------

#site-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 48px 120px;
  z-index: 9;

  .logo {
    height: 40px;
  }
}

#site-header-compact {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 48px 120px;
  z-index: 9;

  .logo {
    height: 30px;
  }
}

// FOOTER
// -------

#site-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $black;
  padding: 48px 120px;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  z-index: -1;

  .copyright {
    font-size: 14px;
    color: rgba($white, 0.4);
  }
}

// HOME
// -------

#home-hero {
  background-color: $white;
  z-index: 2;

  .hero-container {
    width: 100vw;
    height: 100vh;
    
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center bottom;
    z-index: 1;
    display: flex;
    flex-flow: column;
    justify-content: center;
    padding-left: 120px;

    .hero-headline {
      font-size: 60px;
      //text-transform: lowercase;
      line-height: 1.3;
      color: $white;
      text-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
    }

    .hero-hint {
      position: absolute;
      top: calc(100vh - 150px);
      left: 120px;
      z-index: 3;

      .hero-hint-line {
        width: 2px;
        height: 300px;
        background-color: $brand-color;
      }

      .hero-hint-label {
        color: $white;
        text-transform: lowercase;
        position: absolute;
        top: 13px;
        left: -10px;
        transform: rotate(-90deg);
      }
    }
  }
}

#home-about {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
  z-index: 1;

  .about-copy {
    width: 600px;
  }
}

#home-products {
  display: flex; 
  flex-flow: row;
  align-items: center;
  position: relative;
  z-index: 1;

  .section-header {
    width: 50vw;
    height: 100vh;
    //background-color: $grey-color;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: flex-start;
    flex: 1;

    h1 {
      font-size: 60px;
      line-height: 1;
      margin: 0;
      margin-left: 120px;
      position: relative;
      padding-right: 20px;
      text-transform: uppercase;

      // &:after {
      //   content: "";
      //   position: absolute;
      //   right: 0;
      //   bottom: 8px;
      //   width: 8px;
      //   height: 8px;
      //   border-radius: 8px;
      //   background-color: $brand-color;
      // }
    }
  }

  #products-index {
    width: 50vw;
    height: 100vh;
    flex: 1;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      background-color: $black;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      height: 100vh;
    }

    ul {
      margin: 0;
      padding-left: calc((50vw - 360px) / 2);
      padding-top: calc((100vh - 370px) / 2);

      li {
        padding-top: 4px;
        padding-bottom: 4px;
        list-style: none;

        a {
          font-size: 25px;
          line-height: 1.32;
          color: $white;
          position: relative;
          z-index: 5;

          &:before {
            content: "";
            width: 8px;
            height: 8px;
            border-radius: 8px;
            background-color: $brand-color;
            position: absolute;
            left: 0;
            top: 50%;
            opacity: 0;
            -webkit-transform: translateY(-35%) scale(0);
            transform: translateY(-35%) scale(0);
            -webkit-transition: all 0.25s ease-out;
            transition: all 0.25s ease-out;
          }

          &:hover,
          &:focus {
            padding-left: 20px;

            &:before {
              opacity: 1;
              -webkit-transform: translateY(-35%) scale(1);
              transform: translateY(-35%) scale(1);
            }
          }

          &.post-link__disabled {
            cursor: pointer;
          }
        }

        .img {
          width: 50vw;
          height: 100vh;
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          background-size: cover;
          opacity: 0;
          -webkit-transition: opacity 0.5s ease-in-out;
          transition: opacity 0.5s ease-in-out;
        }

        &.animate {

          .img {
            opacity: 0.3;
          }

        }
      }
    }
  }
}

#home-banner {
  width: 100vw;
  height: 600px;
  position: relative;
  z-index: 1;

  .banner-container {
    width: 100vw;
    height: 600px;
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    z-index: 1;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;

    h1 {
      font-size: 50px;
      color: $white;
      text-align: center;
    }
  }
}

#home-contact {
  padding: 120px;
  padding-right: 0;
  background-color: $white;
  position: relative;
  margin-bottom: 129px;
  z-index: 1;

  .contact-container {
    padding-top: 80px;
    padding-bottom: 80px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right center;
  }

  .contact-copy {
    width: 500px;
    padding: 60px 100px;
    background-color: $grey-color;

    address {
      font-style: normal;

      h3 {
        font-family: $base-font-family;
        font-weight: 700;
        font-size: 32px;
        line-height: 1.1875;
        margin-bottom: 32px;
      }

      .adr {
        margin-bottom: 24px;
      }

      .tel a {
        color: $text-color;
      }
    }
  }
}

// POST
// -------

.post-content {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: #EDEDED;
  display: flex;
  display: -ms-flexbox;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 38vh;
    z-index: 1;
  }

  .brand {
    position: absolute;
    left: 30px;
    top: 30px;
    z-index: 2;
  }

  .container  {
    position: relative;
    z-index: 2;
  }

  #back {
    position: absolute;
    right: 30px;
    top: 30px;
    z-index: 2;

    a {
      display: flex;
      flex-flow: row;
      align-items: center;
    }

    .hint {
      text-align: right;
      margin-right: 8px;

      h5 {
        font-family: $base-font-family;
        font-size: 16px;
        color: rgba(0,0,0,0.87);
        font-weight: 300;
        margin: 0;
        line-height: 1.1875;
      }
    
      h6 {
        font-family: $base-font-family;
        font-size: 10px;
        color: rgba(0,0,0,0.38);
        letter-spacing: 0.5px;
        text-transform: uppercase;
        line-height: 1;
        margin: 0;
      }
    }
  }
}

.post-header {
  margin-top: 80px;
  margin-bottom: 30px;

  .post-title {
    font-family: $base-font-family;
    font-size: 36px;
    letter-spacing: -0.5px;
    font-weight: 300;
  }
}

.post-main {
  min-width: 1000px;
  height: 60vh; 
  overflow-x: hidden;
  overflow-y: scroll;
  z-index: 2;
  background: $white;
  box-shadow: 0 5px 15px 0 rgba(0,0,0,0.10);
  border-radius: 4px;
  position: relative;
  
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: block;
  }

  table {
    min-width: 1000px;
  }

  .textarea {
    display: flex;
    padding-top: 15vh;

    ul {
      margin-left: auto;
      margin-right: auto;
    }

    li {
      font-family: $base-title-family;
      font-size: 28px;
      font-weight: 700;
    }
  }

  .watermark {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    opacity: 0.15;
  }
}

.post-footer {
  display: flex;
  flex-flow: row;
  padding-top: 40px;
  height: 150px;

  .post-navigation {
    flex: 1;

    &.navigation-next {
      text-align: right;
    }
  }

  .post-remark {
    flex: 1;

    h5 {
      display: flex;
      flex-flow: row;

      .remark-label {
        margin-right: 0.2rem;
      }
    }
  }

  h5 {
    font-family: $base-font-family;
    font-size: 16px;
    color: rgba(0,0,0,0.87);
    font-weight: 300;
    margin: 0;
    line-height: 1.1875;
  }

  h6 {
    font-family: $base-font-family;
    font-size: 10px;
    color: rgba(0,0,0,0.38);
    letter-spacing: 0.5px;
    text-transform: uppercase;
    line-height: 1;
    margin: 0;
  }
}